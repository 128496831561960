import { ChainId, JSBI, Percent, Token, WETH } from '@kittypunchxyz/punchswap-v2-sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { injected, coinbaseWallet } from '../connectors'

export const ROUTER_ADDRESS = '0xf45AFe28fd5519d5f8C1d4787a4D5f724C0eFa4d'
export const POOL_FACTORY_ADDRESS = '0x29372c22459a4e373851798bFd6808e71EA34A71'

// Pool factory ABI
export const POOL_FACTORY_ABI = [
  {
    constant: true,
    inputs: [],
    name: 'allPairsLength',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'allPairs',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  }
]

// Pair ABI
export const PAIR_ABI = [
  {
    constant: true,
    inputs: [],
    name: 'token0',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'token1',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'getReserves',
    outputs: [
      { internalType: 'uint112', name: '_reserve0', type: 'uint112' },
      { internalType: 'uint112', name: '_reserve1', type: 'uint112' },
      { internalType: 'uint32', name: '_blockTimestampLast', type: 'uint32' }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  }
]

// ERC-20 ABI
export const ERC20_ABI = [
  {
    constant: true,
    inputs: [],
    name: 'name',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'totalSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  }
]

// A list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}
export const PUNCH = new Token(
  ChainId.FLOW_MAINNET,
  '0x57Ea5E73e8C31DFd49046cEBCAf21E51816d4901',
  18,
  'PUNCH',
  'PUNCH'
)
export const USDC = new Token(
  ChainId.FLOW_MAINNET,
  '0xB55D6642c932CF8cBc1A46Ec95B82395a20743dD',
  18,
  'USDC',
  'USDC'
)
export const WFLOW = new Token(
  ChainId.FLOW_MAINNET,
  '0xd3bF53DAC106A0290B0483EcBC89d40FcC961f3e',
  18,
  'WFLOW',
  'Wrapped Flow'
)

const WETH_ONLY: ChainTokenList = {
  [ChainId.FLOW_MAINNET]: [WFLOW]
}

// Used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.FLOW_MAINNET]: [...WETH_ONLY[ChainId.FLOW_MAINNET]]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.FLOW_MAINNET]: {
    [PUNCH.address]: [USDC, WETH[ChainId.FLOW_MAINNET]]
  }
}

// Used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.FLOW_MAINNET]: [...WETH_ONLY[ChainId.FLOW_MAINNET]]
}

// Used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.FLOW_MAINNET]: [...WETH_ONLY[ChainId.FLOW_MAINNET]]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.FLOW_MAINNET]: [
    [
      new Token(ChainId.FLOW_MAINNET, '0x3929f04aD401C048B1BD8d810658cFcC76270080', 8, 'PUNCH', 'Kitty Token'),
      new Token(ChainId.FLOW_MAINNET, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 8, 'USDC', 'Kitty Token')
    ],
    [PUNCH, USDC]
  ]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  COINBASE: {
    connector: coinbaseWallet,
    name: 'Coinbase Wallet',
    iconName: 'coinbase.png', 
    description: 'Connect to Coinbase Wallet',
    href: null,
    color: '#315CF5'
  }
}

export const NetworkContextName = 'NETWORK'

// Default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// One basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// Used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// If the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// For non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// Used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))
