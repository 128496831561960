import React from 'react'
import styled from 'styled-components'
import { RowBetween } from '../Row'

const FooterFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0;
    width: calc(100%);
    position: relative;
  `};
`

const FooterElement = styled.div`
  display: flex;
  align-items: center;
`

const FooterElementWrap = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0.5rem;
  `};
`

const FooterTextLink = styled.a`
  color: ${({ theme }) => theme.text1};
  text-decoration: none;
  margin-right: 16px;
  font-size: 16px;
  &:hover {
    color: ${({ theme }) => theme.primary1};
  }
`

export default function Footer() {
  return (
    <FooterFrame>
      <RowBetween style={{ alignItems: 'center' }} padding="1rem 1rem 0 1rem">
        <FooterElement>
          <FooterTextLink href="https://kittypunch.gitbook.io/" target="_blank" rel="noopener noreferrer">
            Documentation
          </FooterTextLink>
          <FooterTextLink href="https://app.kittypunch.xyz/" target="_blank" rel="noopener noreferrer">
            Core App
          </FooterTextLink>
        </FooterElement>
        <FooterElementWrap>
          {/* Add any additional footer elements here */}
        </FooterElementWrap>
      </RowBetween>
    </FooterFrame>
  )
}