import { Web3Provider } from '@ethersproject/providers'
import { ChainId } from '@kittypunchxyz/punchswap-v2-sdk'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { injected } from '../connectors'
import { NetworkContextName } from '../constants'

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> & { chainId?: ChainId } {
  const context = useWeb3ReactCore<Web3Provider>()
  const contextNetwork = useWeb3ReactCore<Web3Provider>(NetworkContextName)
  return context.active ? context : contextNetwork
}

export function useEagerConnect() {
  const { activate, active, setError } = useWeb3ReactCore()
  const [tried, setTried] = useState(false)

  useEffect(() => {
    const previouslyConnected = localStorage.getItem('connected') === 'true'

    const attemptActivation = () => {
      injected.isAuthorized().then(isAuthorized => {
        if (isAuthorized || previouslyConnected) {
          activate(injected, undefined, true).catch((error) => {
            console.error('Failed to activate on page load', error)
            setError(new Error('Failed to activate on page load'))
            setTried(true)
          })
        } else {
          if (isMobile && window.ethereum) {
            activate(injected, undefined, true).catch((error) => {
              console.error('Failed to activate on mobile', error)
              setError(new Error('Failed to activate on mobile'))
              setTried(true)
            })
          } else {
            setTried(true)
          }
        }
      })
    }

    // Introduce a delay before attempting to activate the wallet
    const timeoutId = setTimeout(() => {
      attemptActivation()
    }, 500) // 500ms delay

    return () => clearTimeout(timeoutId) // Cleanup the timeout on unmount
  }, [activate, setError])

  useEffect(() => {
    if (active) {
      localStorage.setItem('connected', 'true')
      setTried(true)
    } else if (tried) {
      localStorage.removeItem('connected')
    }
  }, [active, tried])

  return tried
}

export function useInactiveListener(suppress = false) {
  const { active, error, activate, setError } = useWeb3ReactCore()

  useEffect(() => {
    const { ethereum } = window

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        activate(injected, undefined, true).catch((error) => {
          console.error('Failed to activate after chain changed', error)
          setError(new Error('Failed to activate after chain changed'))
        })
      }

      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          activate(injected, undefined, true).catch((error) => {
            console.error('Failed to activate after accounts changed', error)
            setError(new Error('Failed to activate after accounts changed'))
          })
        }
      }

      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
        }
      }
    }
    return undefined
  }, [active, error, suppress, activate, setError])
}