import React, { useEffect, useState } from 'react';
import { Contract } from '@ethersproject/contracts';
import { useWeb3React } from '@web3-react/core';
import { formatUnits } from '@ethersproject/units';
import { ERC20_ABI, PAIR_ABI } from '../../constants';
import { getProviderOrSigner } from '../../utils';
import { AutoColumn } from '../../components/Column';
import { LightCard } from '../../components/Card';
import { TYPE as TEXT_TYPE } from '../../theme';
import styled from 'styled-components';
import axios from 'axios';
import { tokenList } from '../../constants/tokenList';
import { Token } from '@kittypunchxyz/punchswap-v2-sdk';

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;
`;

const Table = styled.table`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text1};
  font-size: 16px;
  text-align: center;
  @media (max-width: 768px) {
    padding: 6px;
    font-size: 14px;
  }
`;

const TableData = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  height: 60px;
  @media (max-width: 768px) {
    padding: 6px;
    font-size: 14px;
  }
`;

const CopyButton = ({ text }: { text: string }) => {
  const handleCopy = () => {
    if (text) {
      navigator.clipboard.writeText(text);
      alert('Copied to clipboard!');
    }
  };

  return (
    <span
      onClick={handleCopy}
      style={{ marginLeft: '8px', cursor: 'pointer' }}
      title="Copy Address"
    >
      <span role="img" aria-label="clipboard">
        📋
      </span>
    </span>
  );
};

const OverviewContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 10px;
  }
`;

const OverviewBox = styled.div`
  background-color: ${({ theme }) => theme.bg3};
  padding: 20px;
  border-radius: 10px;
  width: 200px;
  text-align: center;
  @media (max-width: 768px) {
    width: 45%;
  }
`;

const OverviewLabel = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.text1};
  margin-bottom: 10px;
`;

const OverviewValue = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.text1};
`;

const TokenTitle = styled(TEXT_TYPE.main)`
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const formatPrice = (price: number): string => {
  if (price >= 1) {
    return price.toFixed(2);
  } else if (price >= 0.01) {
    return price.toFixed(4);
  } else if (price >= 0.000001) {
    return price.toFixed(6);
  } else {
    return price.toFixed(8);
  }
};

const TokenOverview: React.FC = () => {
  const { library, account, chainId } = useWeb3React();
  const [tokens, setTokens] = useState<any[]>([]);
  const [flowPrice, setFlowPrice] = useState<number>(0);
  const [usdcPrice, setUsdcPrice] = useState<number>(1); // Default USDC price is 1

  useEffect(() => {
    const fetchTokens = async () => {
      if (!library || !account || !chainId) return;

      const provider = getProviderOrSigner(library, account);
      const tokenData: any[] = [];

      for (const { token, pairAddress } of tokenList) {
        try {
          const tokenContract = new Contract(token.address, ERC20_ABI, provider);
          const pairContract = new Contract(pairAddress, PAIR_ABI, provider);

          const [totalSupplyRaw, reserves] = await Promise.all([
            tokenContract.totalSupply(),
            pairContract.getReserves(),
          ]);

          const totalSupply = Number(formatUnits(totalSupplyRaw, token.decimals));
          const tokenPrice = calculateTokenPrice(reserves, token, flowPrice);
          const marketCap = totalSupply * tokenPrice;

          tokenData.push({
            address: token.address,
            name: token.name,
            price: tokenPrice,
            marketCap,
          });
        } catch (error) {
          console.error(`Error fetching data for token ${token.address}:`, error);

          tokenData.push({
            address: token.address ?? '',
            name: token.name ?? 'Unknown',
            price: 0,
            marketCap: 0,
          });
        }
      }

      setTokens(tokenData);
    };

    const fetchPrices = async () => {
      const response = await axios.get(
        'https://api.coingecko.com/api/v3/simple/price?ids=flow,usd-coin&vs_currencies=usd'
      );
      setFlowPrice(response.data.flow.usd);
      setUsdcPrice(response.data['usd-coin'].usd); // Set USDC price
    };

    fetchTokens();
    fetchPrices();
  }, [library, account, chainId, flowPrice, usdcPrice]);

  const calculateTokenPrice = (reserves: any, token: Token, flowPrice: number) => {
    const reserve0 = Number(formatUnits(reserves._reserve0, 18));
    const reserve1 = Number(formatUnits(reserves._reserve1, 18));
    
    // Check for ankrFLOW paired with WFLOW
    if (token.symbol === 'ankrFLOWEVM') {
      const reserve0Value = reserve0;
      const reserve1Value = reserve1 * flowPrice; // WFLOW priced in FLOW
      return reserve1Value / reserve0Value;
    }
  
    // Fallback for other tokens like FROTH
    if (token.symbol === 'FROTH') {
      return reserve1 / reserve0; // Calculate FROTH price as reserve1 / reserve0
    }
  
    // Default fallback price if no specific pair is matched
    return 1;
  };

  return (
    <AutoColumn gap="lg" justify="center">
      <LightCard padding="40px">
        <OverviewContainer>
          <OverviewBox>
            <OverviewLabel>Flow Price:</OverviewLabel>
            <OverviewValue>${flowPrice.toFixed(2)}</OverviewValue>
          </OverviewBox>
          <OverviewBox>
            <OverviewLabel>USDC Price:</OverviewLabel>
            <OverviewValue>${usdcPrice.toFixed(2)}</OverviewValue>
          </OverviewBox>
        </OverviewContainer>

        <TokenTitle>Token Overview</TokenTitle>

        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <TableHeader>Token Name</TableHeader>
                <TableHeader>Price (USD)</TableHeader>
                <TableHeader>Market Cap (USD)</TableHeader>
              </tr>
            </thead>
            <tbody>
              {tokens.map((token, index) => (
                <tr key={index}>
                  <TableData>
                    {token.name ?? 'Unknown'}
                    <CopyButton text={token.address ?? ''} />
                  </TableData>
                  <TableData>${formatPrice(token.price)}</TableData>
                  <TableData>${token.marketCap.toLocaleString(undefined, { maximumFractionDigits: 2 })}</TableData>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      </LightCard>
    </AutoColumn>
  );
};

export default TokenOverview;