// src/constants/tokenList.ts

import { Token, ChainId } from '@kittypunchxyz/punchswap-v2-sdk';

interface TokenInfo {
  token: Token;
  pairAddress: string;  // The pair address of the liquidity pool
}

export const tokenList: TokenInfo[] = [
  {
    token: new Token(ChainId.FLOW_MAINNET, '0x9C7Efd8ecD7C5e8b51a3833F6FB67c6E2F4f4a9B', 18, 'FROTH', 'Froth'),
    pairAddress: '0x1120BD78e9D235Ef1B04fE166dB7454E3ea04D32', 
  },
  {
    token: new Token(ChainId.FLOW_MAINNET, '0x1b97100eA1D7126C4d60027e231EA4CB25314bdb', 18, 'ankrFLOWEVM', 'ankrFLOW'),
    pairAddress: '0x442aE0F33d66F617AF9106e797fc251B574aEdb3', 
  },
];